import { Lobby } from "@entities/lobby";
import { Skeleton } from "@shared/ui/skeleton";

import { useDesktopBanners } from "../lib/use-banner";

import { BannersListDesktop } from "./banners-list.desktop";

export function BannerPanelDesktop() {
  const { isPending } = Lobby.useBanner();
  const banners = useDesktopBanners();

  const isBannerEmpty = !banners || banners.length === 0;

  return (
    (isPending || !isBannerEmpty) && (
      <div className="[aspect-ratio:7.46]">
        <div className="grid grid-cols-3 gap-x-5 h-full relative group">
          {isPending && (
            <>
              <Skeleton className="bg-silver h-full" />
              <Skeleton className="bg-silver h-full" />
              <Skeleton className="bg-silver h-full" />
            </>
          )}

          {!isBannerEmpty && <BannersListDesktop banners={banners} />}
        </div>
      </div>
    )
  );
}
